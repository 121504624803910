import React, {useState} from "react"
import Video from "../video"
import ReactPlayer from 'react-player/youtube'


const VideoGallery = (props) => {

  const { post } = props
  const videos = post?.field_video_gallery
  const [youtubeUrl, setYoutubeUrl] = useState(videos[0]?.uri)
  const changeVideo = (video) => {
    setYoutubeUrl(video)
  }
  return(
    <div className="offset-lg-1 col-md-12 col-lg-10 p-0 video-gallery">
     <div className="video-gallery-title"><h3 className="H3-Bold-L---bordeaux m-b-32">Els nostres vídeos</h3></div>
      <div className="row">
      <div className="col-md-6 main-video small-m-b-32">
        <ReactPlayer
          url={youtubeUrl}
        />
      </div>
      <div className="col-md-6 videos-list">
      {videos && videos.map((video, index) => {
        return(
            <div key={index} className="col-md-4- video-item" >
            <button onClick={() => changeVideo(video.uri)} >
            <img
              src={`https://ytimg.googleusercontent.com/vi/${
                video.uri.split('/').pop()
              }/sddefault.jpg`}
              alt={video.title}
            /> {video.title}
            </button>
          </div>
        )
      })}
      </div>

      </div>
    </div>
  )
}

export default VideoGallery
