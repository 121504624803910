import React from "react"
import Gallery from "./photo-gallery"
import VideoGallery from "./video-gallery"
import Sidebar from "./sidebar"
import Description from "./section-description"
import Item from "../item"
import Utils from "../../utils"
import ButtonModal from "../buttons/button-cta-top-content"

const SimpleContent = ( props) => {

  const {post, t} = props
  return(
    <React.Fragment>
    <section className="m-t-64 m-b-80 small-m-t-40 small-m-b-40 medium-m-b-24">
      <div className="container">
        <div className="row small-m-0">
          <div className="col-md-10 offset-md-1 small-p-l-16 medium-100">
            <h1 className="orange d-flex justify-content-center p-t-72 p-b-25 h1-int-small-font small-p-t-24 small-p-b-13 medium-p-t-24 h1-int-medium-font">
              {post?.title}
            </h1>
            <Description
              title={post?.body?.summary}
              t={t}
              showKeepReading={false}
            />
         </div>
        </div>
        <div className="row">
         <div className="col-md-10 offset-md-1 img-interior-ministry p-0 small-p-l-16 medium-100">
           <img
            src={post?.relationships?.field_image?.image_style_uri?._1103x488}
            alt={post?.title}
            />
         </div>
        </div>
      </div>
      </section>
      <section className="interior-content">
       <div className="container">
        <div className="row small-m-0">
          <div className="col-12 col-md-6 p-0 m-r-20 offset-md-1 small-m-r-0 small-p-l-r-5 medium-m-l-0">
            <div
              dangerouslySetInnerHTML={{__html: post?.body?.processed}}
              className="simple-page p-b-80 small-p-b-40"
            />

            <div className="button-wrapper align-center w-100">
              <ButtonModal
                title={"Vull fer voluntariat"}
                backgroundimage={post?.relationships?.field_image?.image_style_uri?._1103x488}
                formId="voluntariat_goel"
              //  formData={formData}
                modalTitle="Fer voluntariat"
                modalDescription=""
                modalFooter=""
                className="btn btn-outline my-2 my-sm-0 orange"
              />
            </div>

            <div className="resources-content">
            {post?.relationships?.field_resource && post?.relationships?.field_resource?.length > 0 &&
              post?.relationships.field_resource.map((item, key) => {
                return(
                  <Item
                    key={key}
                    className="col-12 col-md-6 col-lg-4 m-b-34"
                    title={item?.title}
                    type={Utils.getType(item?.type)}
                    description={Utils.getDescription(item)}
                    labelCta="Veure més"
                    path={item?.field_cta?.url.replace("/ca", "")}
                    readmore={item.type === 'node--blog_post' ? "Llegir-ho" : "Veure més"}
                    icon={item.type === 'node--blog_post' ? "icons-32-x-32-generic-plus" : ""}
                    imageUrl={item?.relationships.field_image.image_style_uri._653_432}
                  />
                )
              })
            }
            </div>


          </div>
            <Sidebar
            />
        </div>

      </div>


      <div className="container media-container">
        <div className="row small-0">
          {post?.relationships?.field_gallery && post?.relationships?.field_gallery?.length > 0 &&
          <Gallery
            post={post}
           />
         }
          {post?.field_video_gallery && post?.field_video_gallery?.length > 0 &&
           <VideoGallery
             post={post}
            />
          }
        </div>
      </div>

      </section>
     </React.Fragment>
  )
}

export default SimpleContent
